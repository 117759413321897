import React from 'react';
import PartnersCarousel from '../components/PartnersCarousel';
import SubscriptionForm from '../components/SubscriptionForm';

function Main() {
  return (
    <div className="container mx-auto py-8 items-center justify-center flex-col flex-1 basis-auto ">
      <div className=" hidden md:flex container mx-auto justify-center w-full relative p-5">
        <img
          className="w-full h-auto object-cover "
          src="/assets/img/main.png"
          alt="Banner"
        />
        <div class="absolute right-[10%] top-1/3 flex  flex-col items-center justify-end text-center text-white ">
          <div class="relative px-4 py-2 bg-white bg-opacity-10 backdrop-blur-sm  rounded-2xl ">
            <h2 class="text-white relative text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl  font-bold tracking-wide drop-shadow-lg">
              GET YOUR BONUS
              <img
                className="absolute -bottom-[20%] -left-[28%] w-1/3"
                src="/assets/img/chip.png"
                alt=""
              />
            </h2>
          </div>
          <a
            href="https://pin-up.ua"
            class="inline-block mt-[10%] text-center px-8 py-2 text-white font-bold text-md md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl rounded-2xl bg-gradient-to-t from-red-400 via-red-700 to-red-400 hover:bg-gradient-to-b hover:from-red-400 hover:via-red-600 hover:to-red-400 shadow-lg transform transition-all duration-300 ease-out hover:scale-105 hover:shadow-2xl"
          >
            +100FS
            <br />
            Bonus 20 000 MXN
          </a>
        </div>
      </div>
      <div className="flex md:hidden  justify-center w-full relative p-5">
        <img
          className="w-full h-auto object-cover max-w-lg md:max-w-xl lg:max-w-2xl"
          src="/assets/img/mob-main.png"
          alt="Banner"
        />
        <div class="absolute inset-10  flex flex-col items-center justify-end text-center text-white mb-[2rem]">
          <div class="relative px-4 py-2 bg-white bg-opacity-10 backdrop-blur-sm  rounded-2xl ">
            <h2 class="text-white text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl  font-bold tracking-wide drop-shadow-lg">
              GET YOUR BONUS
            </h2>
          </div>
          <a
            href="https://pin-up.ua"
            class="mt-4 inline-block text-center px-5 pb-1 text-white font-bold text-sm	 rounded-2xl bg-gradient-to-t from-red-400 via-red-700 to-red-400 hover:bg-gradient-to-b hover:from-red-400 hover:via-red-600 hover:to-red-400 shadow-lg transform transition-all duration-300 ease-out hover:scale-105 hover:shadow-2xl"
          >
            +100FS
            <br />
            Bonus 20 000 MXN
          </a>
        </div>
      </div>
      <SubscriptionForm />
      <div className="bg-gradient-to-r  from-[#03206C8F] to-[#0466C8] text-white p-6  mt-6 ml-4 mr-4 rounded-lg shadow-lg ">
        <h2 className="text-2xl font-bold mb-4 text-center">
          PLEI KRIEITIV - su guía de confianza en iGaming
        </h2>
        <p className="text-lg text-center">
          PLEI KRIEITIV es un servicio de información moderno en el ámbito de
          iGaming, que ofrece noticias actuales, ofertas únicas y promociones
          exclusivas de los mejores socios de casino. Nos esforzamos por ayudar
          a cada cliente a disfrutar al máximo de la experiencia de juego,
          proporcionando información verificada y bonificaciones exclusivas.
          Nuestros socios son plataformas confiables y reconocidas, y nuestra
          misión es ser su guía de confianza en el mundo del entretenimiento en
          línea.
        </p>
      </div>
      <PartnersCarousel />
    </div>
  );
}

export default Main;
