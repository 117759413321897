import React, { useState } from 'react';
import { Link } from 'react-router-dom';
function SubscriptionForm() {
  const [email, setEmail] = useState('');
  const [consent, setConsent] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email && consent) {
      console.log('Form submitted with:', { email });
      setEmail('');
      setConsent(false);
    } else {
      alert('Por favor, completa todos los campos correctamente.');
    }
  };

  return (
    <div className="bg-gray-900 p-6 mx-4 rounded-lg">
      <h3 className="text-lg text-white font-semibold text-center">
        Suscríbete al boletín
      </h3>
      <p className="text-center text-gray-400">
        Sé el primero en conocer novedades, ofertas especiales y bonificaciones
        exclusivas de nuestros socios en el mundo de iGaming.
      </p>

      <form className="mt-4" onSubmit={handleSubmit}>
        <div className="flex items-center space-x-2">
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="example@domain.com"
            className="flex-1 px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring focus:ring-[#0466C8]"
            required
          />
          <button
            type="submit"
            className="py-2 px-4 bg-[#0466C8] text-white rounded-lg font-semibold hover:opacity-80 focus:outline-none focus:ring focus:ring-[#0466C8]"
          >
            Suscribir
          </button>
        </div>

        <div className="flex items-center space-x-2 mt-4">
          <input
            type="checkbox"
            id="consent"
            checked={consent}
            onChange={(e) => setConsent(e.target.checked)}
            className="text-[#0466C8] focus:ring-[#0466C8]"
            required
          />
          <label htmlFor="consent" className="text-sm text-gray-400">
            Acepto recibir notificaciones sobre novedades y ofertas especiales
          </label>
        </div>

        <p className="text-xs text-center text-gray-400 mt-4">
          Al suscribirte al boletín, aceptas nuestra
          <Link
            to={'/privacy-policy'}
            className="underline mx-1 text-[#0466C8]"
          >
            Política de Privacidad
          </Link>
          y consientes el procesamiento de tus datos personales por parte de{' '}
          <strong>PLEI KRIEITIV TOV</strong>.
        </p>
      </form>
    </div>
  );
}

export default SubscriptionForm;
