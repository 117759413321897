import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

const PartnersCarousel = () => {
  return (
    <div className="container mx-auto py-8">
      <h2 className="text-3xl text-white font-bold text-center mb-6">Socios</h2>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        loop={true}
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        className="swiper-container"
      >
        <SwiperSlide>
          <img
            src="/assets/img/partners/melbet.webp"
            alt="melbet"
            className="w-full h-[150px] object-contain"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="/assets/img/partners/bet7k.webp"
            alt="bet7k"
            className="w-full h-[150px] object-contain"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="/assets/img/partners/parimatch.webp"
            alt="parimatch"
            className="w-full h-[150px] object-contain"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="/assets/img/partners/1win.webp"
            alt="1win"
            className="w-full h-[150px] object-contain"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="/assets/img/partners/pinup.svg"
            alt="pinup"
            className="w-full h-[150px] object-contain"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default PartnersCarousel;
