import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 text-start flex-1 basis-auto">
      <h1 className="text-3xl font-bold mb-4 text-center text-white">
        Política de privacidad
      </h1>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-white">
          1. Sobre la Compañía
        </h2>
        <p className="text-gray-400">
          PLEI KRIEITIV TOV, Número D-U-N-S®: 498441681. Dirección de la
          oficina: Edificio 42-44, oficina 4E, Calle Shovkovychna, 01004, Kiev,
          Ucrania. Ofrecemos servicios de información en el sector de iGaming y
          nos comprometemos a la transparencia y a la protección de los datos de
          todos nuestros usuarios.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-white">
          2. Qué datos recopilamos
        </h2>
        <ul className="list-disc list-inside text-gray-400">
          <li>
            <strong>Información de contacto</strong>: nombre, dirección de
            correo electrónico, número de teléfono.
          </li>
          <li>
            <strong>Datos técnicos</strong>: dirección IP, información del
            navegador y dispositivo, datos sobre el uso de nuestro sitio (hora
            de visita, páginas que visita).
          </li>
          <li>
            <strong>Datos proporcionados voluntariamente</strong>: información
            que usted proporciona al completar formularios en nuestro sitio.
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-white">
          3. Finalidades de la recopilación de datos
        </h2>
        <p className="text-gray-400">
          Utilizamos sus datos personales con los siguientes fines:
        </p>
        <ul className="list-disc list-inside text-gray-400">
          <li>
            <strong>Para ofrecer servicios</strong>: facilitarle el acceso a
            nuestros servicios y recursos.
          </li>
          <li>
            <strong>Para mejorar nuestros servicios</strong>: analizar cómo usa
            usted nuestro sitio para optimizar su funcionamiento.
          </li>
          <li>
            <strong>Para comunicarnos con usted</strong>: responder a sus
            preguntas y solicitudes.
          </li>
          <li>
            <strong>Para marketing</strong>: enviarle información sobre
            novedades, promociones y descuentos.
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-white">
          4. Con quién podemos compartir sus datos
        </h2>
        <p className="text-gray-400">
          Podemos compartir sus datos personales con terceros solo en los
          siguientes casos:
        </p>
        <ul className="list-disc list-inside text-gray-400">
          <li>
            <strong>Con socios y contratistas</strong>: para procesar datos en
            nuestro nombre.
          </li>
          <li>
            <strong>Con autoridades gubernamentales</strong>: cuando exista una
            base legal para divulgar los datos.
          </li>
          <li>
            <strong>Con terceros autorizados</strong>: si usted ha dado su
            consentimiento para compartir los datos.
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-white">
          5. Protección de los datos
        </h2>
        <p className="text-gray-400">
          Tomamos medidas técnicas y organizativas para proteger sus datos
          contra el acceso no autorizado, la pérdida, la alteración o la
          divulgación.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-white">
          6. Periodo de conservación de los datos
        </h2>
        <p className="text-gray-400">
          Conservamos sus datos personales únicamente durante el tiempo
          necesario para alcanzar los fines para los que se recopilaron.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-white">
          7. Sus derechos
        </h2>
        <p className="text-gray-400">
          De acuerdo con la legislación de Ucrania, usted tiene derecho a:
        </p>
        <ul className="list-disc list-inside text-gray-400">
          <li>Acceder a sus datos y solicitar una copia de los mismos.</li>
          <li>Corregir o actualizar sus datos si son incorrectos.</li>
          <li>
            Solicitar la eliminación de sus datos si ya no son necesarios.
          </li>
          <li>
            Restringir el procesamiento de sus datos si existen razones legales
            para hacerlo.
          </li>
          <li>Retirar su consentimiento para el procesamiento de datos.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-2 text-white">
          8. Cambios en la política de privacidad
        </h2>
        <p className="text-gray-400">
          Podemos actualizar esta política de privacidad periódicamente para
          cumplir con cambios en la legislación.
        </p>
      </section>
      <h1 className="text-3xl font-bold mb-4 text-center text-white">
        Términos de Uso
      </h1>
      <p className="text-gray-400">
        Bienvenido al sitio web de <strong>PLEI KRIEITIV TOV</strong>. Al
        navegar o utilizar nuestro sitio, usted acepta estos términos de uso. Si
        no está de acuerdo con estos términos, por favor, no utilice nuestro
        sitio.
      </p>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-white">
          1. Acceso al sitio web
        </h2>
        <ul className="list-disc list-inside text-gray-400">
          <li>
            Proporcionamos acceso a nuestro sitio web de manera temporal y nos
            reservamos el derecho de modificar, suspender o interrumpir los
            servicios en el sitio sin previo aviso. No seremos responsables si
            el sitio no está disponible en algún momento o durante algún
            período.
          </li>
          <li>
            Podemos limitar el acceso a ciertas partes del sitio para usuarios
            registrados o introducir otras restricciones según sea necesario.
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-white">
          2. Exactitud de la información
        </h2>
        <ul className="list-disc list-inside text-gray-400">
          <li>
            <strong>PLEI KRIEITIV TOV</strong> se esfuerza por proporcionar
            información precisa y actualizada, sin embargo, no garantizamos que
            la información en nuestro sitio sea completa o esté al día. Toda la
            información se proporciona “tal cual”, y la Compañía no se
            responsabiliza por posibles errores u omisiones.
          </li>
          <li>
            Nos reservamos el derecho de actualizar, modificar o eliminar la
            información en el sitio en cualquier momento. Debería revisar esta
            sección regularmente en caso de cambios, ya que el uso continuado
            del sitio después de la publicación de los cambios se considerará
            como su aceptación de los nuevos términos.
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-white">
          4. Uso permitido
        </h2>
        <p className="text-gray-400">
          Permitimos el uso de nuestro sitio solo con fines legales. Usted
          acepta no utilizar el sitio para:
        </p>
        <ul className="list-disc list-inside text-gray-400">
          <li>
            Cualquier actividad que viole leyes y normativas locales o
            internacionales.
          </li>
          <li>
            Cualquier actividad fraudulenta, incluida la presentación de
            información falsa.
          </li>
          <li>
            Publicar, cargar o transmitir material que sea ofensivo, obsceno o
            que de otra manera infrinja los derechos de otras personas.
          </li>
          <li>
            Intentar hackear, difundir programas maliciosos, virus o cualquier
            otro acto malintencionado destinado a interrumpir el funcionamiento
            del sitio o de los equipos.
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-white">
          5. Protección de la propiedad intelectual
        </h2>
        <p className="text-gray-400">
          Todos los materiales en el sitio, incluyendo textos, imágenes,
          logotipos, gráficos, diseños y otros elementos, son propiedad de{' '}
          <strong>PLEI KRIEITIV TOV</strong> o de sus socios y están protegidos
          por derechos de autor. Cualquier reproducción, distribución,
          publicación o uso de los materiales sin nuestro consentimiento previo
          está prohibido.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-2 text-white">Contacto</h2>
        <p className="text-gray-400">
          Si tiene alguna pregunta sobre el uso de nuestro sitio o desea obtener
          información adicional, por favor, póngase en contacto con nosotros:
        </p>
        <p className="text-gray-400">
          <strong>PLEI KRIEITIV TOV</strong>
          <br />
          Dirección: Edificio 42-44, oficina 4E, Calle Shovkovychna, 01004,
          Kiev, Ucrania.
          <br />
          Correo electrónico:{' '}
          <a
            href="mailto:admin@etrela.top"
            className="text-blue-500 hover:underline"
          >
            admin@etrela.top
          </a>
          <br />
          +380 98 058 87 47
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
