import React from 'react';

function NoPage() {
  return (
    <>
      <h1 className="flex-1 basis-auto margin-0 text-white flex items-center justify-center h-screen font-bold text-6xl ">
        No page 404
      </h1>
    </>
  );
}

export default NoPage;
