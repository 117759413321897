import React from 'react';

const ContactUs = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 flex-1 basis-auto min-h-full">
      <h1 className="text-3xl font-bold mb-4 text-white">
        ¡Mantengámonos en contacto!
      </h1>
      <p className="text-gray-300 mb-6">
        Siempre estaremos encantados de responder a sus preguntas y escuchar
        sugerencias. Si tienes una petición, sugerencia o simplemente un mensaje
        interesante, escríbenos. ¡Estamos en contacto y listos para ayudar!
      </p>

      <form
        id="contactForm"
        className="flex mx-auto flex-col gap-6 max-w-lg items-center"
      >
        <input
          type="text"
          id="name"
          placeholder="Tu nombre"
          required
          className="w-full bg-gray-800 text-white placeholder-gray-400 px-4 py-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="email"
          id="email"
          placeholder="Tu correo electrónico"
          required
          className="w-full bg-gray-800 text-white placeholder-gray-400 px-4 py-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <textarea
          id="message"
          placeholder="Tu mensaje"
          required
          className="w-full bg-gray-800 text-white placeholder-gray-400 px-4 py-3 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-32"
        ></textarea>
        <div className="flex gap-10">
          <button
            type="submit"
            className="bg-blue-500 text-white font-bold px-8 py-3 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 max-w-xs mx-auto"
          >
            Enviar
          </button>
          <a
            href="#"
            className="inline-block px-8 py-3 bg-[#03206C8F] hover:opacity-80 transition-all duration-100 text-white rounded-lg font-bold text-lg "
          >
            Regresar
          </a>
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
