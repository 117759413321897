import React from 'react';

const AboutUs = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 text-start flex-1 basis-auto  ">
      <h1 className="text-3xl font-bold mb-4 text-center text-white">
        Sobre nosotras
      </h1>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-white">
          1. Descripción principal de la empresa
        </h2>
        <p className="text-gray-400">
          Somos un servicio de información que se especializa en proporcionar
          información actual y útil en el ámbito del iGaming, con un enfoque en
          los casinos. Nuestra misión principal es ayudar a los clientes
          actuales y potenciales a mantenerse al tanto de las últimas ofertas,
          promociones, descuentos y bonificaciones disponibles en las
          plataformas de nuestros socios.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-white">
          2. Nuestro papel en la industria
        </h2>
        <p className="text-gray-400">
          Al trabajar en estrecha colaboración con nuestros socios en el ámbito
          de los casinos en línea y otras plataformas de entretenimiento,
          buscamos brindar el máximo valor a los usuarios. Proporcionamos a
          nuestros clientes información completa sobre ofertas únicas que no
          solo aumentan su interés por los juegos, sino que también les permiten
          aprovechar al máximo las oportunidades que ofrecen nuestros socios.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-white">
          3. ¿Qué ofrecemos a nuestros usuarios?
        </h2>
        <ul className="list-disc list-inside text-gray-400">
          <li>
            <strong>Ofertas y bonificaciones exclusivas</strong>: Solo aquí
            puede enterarse de todas las promociones especiales y ofertas
            disponibles de nuestros socios. Esto incluye diversos bonos de
            depósito, giros gratis y otros tipos de recompensas.
          </li>
          <li>
            <strong>Descuentos y promociones</strong>: Actualizamos regularmente
            la información sobre descuentos y promociones para que los usuarios
            puedan participar y disfrutar más de los juegos.
          </li>
          <li>
            <strong>Noticias y actualizaciones de la industria</strong>:
            Seguimos las últimas tendencias y actualizaciones de plataformas y
            juegos, para que nuestros clientes siempre sepan dónde y cuándo
            aparecen nuevas oportunidades.
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-2 text-white">
          4. ¿Por qué elegirnos?
        </h2>
        <ul className="list-disc list-inside text-gray-400">
          <li>
            <strong>Socios confiables</strong>: Solo trabajamos con socios
            verificados y confiables en la industria, lo que garantiza la
            seguridad y la honestidad de todas las promociones ofrecidas.
          </li>
          <li>
            <strong>Información veraz</strong>: Proporcionamos solo información
            comprobada y actual, en la que se puede confiar.
          </li>
          <li>
            <strong>Facilidad de uso</strong>: Nuestra plataforma está diseñada
            para que la información sea accesible y fácil de entender, incluso
            si el usuario apenas está comenzando a familiarizarse con el mundo
            del iGaming.
          </li>
        </ul>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-2 text-white">
          5. Nuestra misión y valores
        </h2>
        <p className="text-gray-400">
          Creemos que cada cliente merece acceso a información confiable y la
          oportunidad de aprovechar las mejores ofertas del mercado. Nuestra
          misión es crear un espacio donde cada usuario pueda obtener
          información beneficiosa tanto para el entretenimiento como para
          obtener bonificaciones adicionales.
        </p>
      </section>
    </div>
  );
};

export default AboutUs;
