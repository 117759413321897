import React from 'react';

function Footer() {
  return (
    <footer class="bg-gradient-to-r from-[#03206C8F] to-[#0466C8] text-white py-2 px-4 md:px-8">
      <div class="max-w-4xl mx-auto space-y-8">
        <div class="text-center">
          <h2 class="text-xl font-semibold">PLEI KRIEITIV TOV</h2>
        </div>

        <div class="text-center space-y-2">
          <p>Teléfono: +380 98 058 87 47 </p>
          <p>Email: dmin@etrela.top</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
