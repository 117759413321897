import { useState } from 'react';
import { Link } from 'react-router-dom';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItems = [
    { label: 'Principal', path: '/' },
    { label: 'Sobre nosotras', path: '/about-us' },
    { label: 'Política de privacidad', path: '/privacy-policy' },
    { label: 'Contacta con nosotras', path: '/contact-us' },
  ];

  return (
    <header>
      <nav className="container mx-auto flex justify-center items-center p-4 relative">
        {/* Burger Icon */}
        <div
          className="absolute right-4 top-4 cursor-pointer space-y-1 z-50"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <span
            className={`block w-8 h-1 bg-white transition-transform duration-300 ${
              isMenuOpen ? 'rotate-45 translate-y-2' : ''
            }`}
          ></span>
          <span
            className={`block w-8 h-1 bg-white transition-opacity duration-300 ${
              isMenuOpen ? 'opacity-0' : ''
            }`}
          ></span>
          <span
            className={`block w-8 h-1 bg-white transition-transform duration-300 ${
              isMenuOpen ? '-rotate-45 -translate-y-2' : ''
            }`}
          ></span>
        </div>

        {/* Menu */}
        <div
          className={`${
            isMenuOpen ? 'flex' : 'hidden'
          } flex-col items-center absolute top-16 w-full bg-blue-900 gap-5 rounded-md p-4 py-8 transition-all duration-300 ease-in-out z-40`}
        >
          {menuItems.map((item, index) => (
            <Link
              key={index}
              className="w-full text-center px-7 py-2 text-xl bg-[#03206C8F] hover:opacity-80 transition-all duration-100 text-white shadow-primary-shadow rounded-md"
              to={item.path}
              onClick={() => setIsMenuOpen(false)}
            >
              {item.label}
            </Link>
          ))}
        </div>
      </nav>
    </header>
  );
}

export default Header;
